import {
  WppTypography,
  WppListItem,
  WppTooltip,
  WppSpinner,
  WppIconInfo,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { useUpdateApp } from 'api/assistant/mutations/apps/useUpdateApp'
import { usePostInteractionEvent } from 'api/assistant/mutations/events/usePostInteractionEvent'
import IconPin from 'assets/icons/pin.svg'
import IconUnpin from 'assets/icons/unpin.svg'
import { Flex } from 'components/common/flex/Flex'
import DynamicIconApp from 'components/common/icons/DynamicIconApp'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useHubsContext } from 'hooks/useHubsContext'
import { useToast } from 'hooks/useToast'
import { AppAPI } from 'types/app/app'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import styles from './AppItem.module.scss'
import ToolbarItem from '../../toolbar/toolbarItem/ToolbarItem'

export interface Props {
  app: AppAPI
  assistantWidth: number
  isSidebarExpanded?: boolean
  isAppPinned: boolean
  type: 'all-apps' | 'recommended-apps' | 'pinned-apps'
  handleMouseEnterCallback?: () => void
}

export const AppItem = ({
  app,
  isSidebarExpanded = false,
  assistantWidth,
  isAppPinned,
  type,
  handleMouseEnterCallback,
}: Props) => {
  const { osApi, osContext } = useOs()
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  // const { toolbarApps } = useApps()

  const { mutateAsync: createEvent } = usePostInteractionEvent()
  const { mutateAsync: updateApp, isPending: loading } = useUpdateApp()
  const { hubs: data } = useHubsContext()

  const closeApp = () => {
    osApi.navigation.openCompactApp({
      name: app.name,
      url: '',
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          width: 0,
          height: 0,
        },
        style: {
          display: 'none',
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })
  }

  // Close app if it's saved in the project context and the project is closed
  useEffect(() => {
    if (appSaved?.id === app.id) {
      // TODO hardcoded solution for the orchestration project page redirect to application
      if (!(osContext.activePage === 'ORCHESTRATION_PAGE' && osContext.baseUrl === '')) {
        if (!osContext.project && app.inProjectContext) {
          closeApp()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.inProjectContext, osContext.project])

  const pinApp = async () => {
    if (type === 'pinned-apps') {
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: EVENTS.ACTIONS.APP_MENU_UNPIN,
          params: [{ key: 'productName', value: app.name }],
        },
      })
    } else if (type === 'all-apps') {
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: appPinned ? EVENTS.ACTIONS.APP_ALL_TOOLS_UNPIN : EVENTS.ACTIONS.APP_PIN,
          params: [{ key: 'productName', value: app.name }],
        },
      })
    }

    try {
      await updateApp({
        pin: !appPinned,
        tenantId: osContext.tenant.id,
        appId: app.id,
        userId: osContext.userDetails.id,
      })
      setAppPinned(!appPinned)
      // Update toolbar query data
      queryClient.setQueriesData({ queryKey: [ApiQueryKeys.TOOLBAR_APPS] }, (oldData: any) => {
        if (appPinned) {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              pinnedApps: oldData.data.pinnedApps.filter((pinnedApp: any) => pinnedApp.id !== app.id),
            },
          }
        } else {
          return {
            ...oldData,
            data: { ...oldData.data, pinnedApps: [...oldData.data.pinnedApps, app] },
          }
        }
      })
      // queryClient.invalidateQueries([ApiQueryKeys.TOOLBAR_APPS])
    } catch (e: any) {
      if (e?.response?.data?.detail) {
        showToast({
          message: e.response.data.detail,
          type: 'error',
          duration: 4000,
        })
      }
      console.error(e)
    }
  }

  const [appSaved, saveAppState] = useState<AppAPI | null>(null)
  const osContextRef = useRef(osContext)
  const dataRef = useRef(data)
  const appSavedRef = useRef(appSaved)

  const [iconVisible, setIconVisible] = useState(false)
  const [appPinned, setAppPinned] = useState(isAppPinned)
  const [appHover, setAppHover] = useState(false)

  const handleOpenApp = (app: AppAPI) => {
    if (app.inProjectContext && !osContext.project) {
      showToast({
        message: 'This application can be launched in the project context only',
        type: 'error',
        duration: 4000,
      })
    } else {
      saveAppState(app)
      openApp(app)
    }
  }

  const openApp = async (app: AppAPI) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.APP_LAUNCH,
        params: [
          { key: 'productName', value: app.name },
          {
            key: 'launchedFrom',
            value:
              type === 'all-apps'
                ? 'AllToolsList'
                : type === 'recommended-apps'
                ? 'AItoolbarMainMenu'
                : 'AItoolbarMainMenu',
          },
          { key: 'productType', value: 'iframe' },
        ],
      },
    })

    try {
      if (data) {
        // Delete unnecessary data from osContext
        const cloneOsContext = JSON.parse(JSON.stringify(osContext))
        delete cloneOsContext.navigationTree
        delete cloneOsContext.theme

        await createEvent({
          context: cloneOsContext,
          userId: osContext.userDetails.id,
          userEmail: osContext.userDetails.email,
          userRole: 'OWNER',
          tenantId: osContext.tenant.id,
          tenantName: osContext.tenant.name,
          hubId: data.length ? data[0].id : '',
          hubName: data.length ? data[0].name : '',
          interactionType: 'tool',
          toolId: app.id,
          toolName: app.name,
        })
      }
    } catch (error) {
      console.error('Error opening app', error)
    }

    osApi.navigation.openCompactApp({
      name: app.name,
      url: app.appUrl,
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          x: window.innerWidth - (assistantWidth + 30) - getOverlayWidth(),
          y: 24,
          width: getOverlayWidth(),
          height: window.innerHeight - 63 - 48,
        },
        style: {
          zIndex: 50,
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })

    if (app.inProjectContext) {
      showToast({
        header: 'This tool uses project-specific data',
        message: 'Leaving this project will close the tool overlay and any content there will be lost.',
        type: 'warning',
        duration: 4000,
      })
      showToast({
        message: `Work done in the tool will be stored in recents in: ${osContext.project?.name}`,
        type: 'information',
        duration: 4000,
      })
    } else {
      const message = `Work done in the tool will be stored in recents in: ${getClientName || 'Client'} / ${
        getMarketName || 'Market'
      } / ${getBrandName || 'Brand'} / Custom`
      showToast({
        message: message,
        type: 'information',
        duration: 4000,
      })
    }
  }

  const getOverlayWidth = () => {
    if (window.innerWidth > 1366) {
      return 760
    }
    return 600
  }

  useEffect(() => {
    osContextRef.current = osContext
    dataRef.current = data
    appSavedRef.current = appSaved
  }, [osContext, data, appSaved])

  const appDisabledProjectContext = useMemo(() => {
    return app.inProjectContext && !osContext.project
  }, [app.inProjectContext, osContext.project])

  const appDisabledExternal = useMemo(() => {
    return (osContext.userDetails as any)?.isExternal && !app.accessibleToExternalUsers
  }, [app.accessibleToExternalUsers, osContext.userDetails])

  const appDisabled = useMemo(() => {
    return appDisabledExternal || appDisabledProjectContext
  }, [appDisabledExternal, appDisabledProjectContext])

  // Functions handling the replacement of placeholders in prompts
  const getClientName = useMemo(() => {
    if (osContext?.hierarchy) {
      for (let key in osContext?.hierarchy.mapping) {
        if (osContext?.hierarchy.mapping[key].type === 'CLIENT') {
          return osContext?.hierarchy.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.hierarchy])
  const getBrandName = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'BRAND') {
          return osContext?.workspace.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.workspace])

  const getMarketName = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'MARKET') {
          return osContext?.workspace.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.workspace])

  return (
    <>
      {type === 'all-apps' && (
        <>
          <WppListItem
            className={clsx(styles.listItem, appDisabled && styles.buttonDisabled, 'cancel-drag')}
            onMouseEnter={() => setIconVisible(true)}
            onMouseLeave={() => setIconVisible(false)}
            onClick={() => !appDisabled && handleOpenApp(app)}
          >
            <Flex key={app.id} slot="label" className={clsx(appDisabled && styles.opacity)}>
              <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
              <WppTypography className={styles.appNameAll} tag="span" type="s-body">
                {app.name}
              </WppTypography>
            </Flex>
            {appPinned && (
              <div slot="right" className={styles.relative}>
                <WppTooltip text="Unpin from toolbar">
                  {!loading ? (
                    <img
                      src={IconPin}
                      alt=""
                      className={styles.pointer}
                      onClick={(e: any) => {
                        pinApp()
                        e.stopPropagation()
                      }}
                    />
                  ) : (
                    <WppSpinner />
                  )}
                </WppTooltip>
                {appDisabledProjectContext && iconVisible ? (
                  <WppTooltip className={styles.infoIcon} text="This app cannot be launched outside of a project">
                    <WppIconInfo />
                  </WppTooltip>
                ) : null}
              </div>
            )}

            {iconVisible && !appPinned && (
              <div slot="right" className={styles.relative}>
                <WppTooltip text="Pin to toolbar">
                  {!loading ? (
                    <img
                      src={IconUnpin}
                      alt=""
                      className={styles.pointer}
                      onClick={(e: any) => {
                        pinApp()
                        e.stopPropagation()
                      }}
                    />
                  ) : (
                    <WppSpinner />
                  )}
                </WppTooltip>

                {appDisabledProjectContext && (
                  <WppTooltip className={styles.infoIcon} text="This app cannot be launched outside of a project">
                    <WppIconInfo />
                  </WppTooltip>
                )}
              </div>
            )}
          </WppListItem>
        </>
      )}

      {type !== 'all-apps' && handleMouseEnterCallback && (
        <Fragment key={app.id}>
          {isSidebarExpanded ? (
            <ToolbarItem
              app={app}
              appDisabled={appDisabled}
              appDisabledProjectContext={appDisabledProjectContext}
              appHover={appHover}
              setAppHover={setAppHover}
              handleOpenApp={handleOpenApp}
              handleMouseEnterCallback={handleMouseEnterCallback}
              pinApp={pinApp}
              type={type}
              isLoading={loading}
              appPinned={appPinned}
            />
          ) : (
            <WppTooltip
              key={app.name}
              className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
              text={app.name}
              config={{ placement: 'left' }}
            >
              <WppActionButton
                className={clsx(styles.button)}
                variant="secondary"
                onClick={() => handleOpenApp(app)}
                onMouseEnter={() => {
                  handleMouseEnterCallback()
                  setAppHover(true)
                }}
                onMouseLeave={() => setAppHover(false)}
                disabled={appDisabled}
              >
                <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
              </WppActionButton>
            </WppTooltip>
          )}
        </Fragment>
      )}
    </>
  )
}
